import AbstractEnumType from 'common/models/enums/types/abstract-enum-type';
import ModelDateTypeComparable from 'common/models/enums/models/date-type-comparable';

export default class TypeDateComparableType extends AbstractEnumType {
  public static readonly DAY_OF_WEEK = new ModelDateTypeComparable(
    'dayOfWeek',
    'application.filter.date-range.date-type-comparable.day-of-week',
  );

  public static readonly CALENDAR_DATE = new ModelDateTypeComparable(
    'calendarDate',
    'application.filter.date-range.date-type-comparable.calendar-date',
  );

  public static readonly ALL: ModelDateTypeComparable[] = this.all(
    TypeDateComparableType,
    ModelDateTypeComparable,
  );

  public static byValue(
    searchValue: string,
    defaultValue?: ModelDateTypeComparable,
  ): ModelDateTypeComparable | null {
    return this.by(
      this,
      ModelDateTypeComparable,
      'value',
      searchValue,
      defaultValue,
    );
  }
}
