import AbstractEnumType from 'common/models/enums/types/abstract-enum-type';
import ModelTableSortMethod from 'common/models/enums/models/ModelTableSortMethod';

export default class TypeTableSortMethodEnum extends AbstractEnumType {
  public static readonly PROGRESSION = new ModelTableSortMethod(
    'PROGRESSION',
    'sort-by-prog',
    false,
  );

  public static readonly VOLUME = new ModelTableSortMethod(
    'VOLUME',
    'sort-by-volume',
    true,
  );

  public static readonly ALL: ModelTableSortMethod[] = this.all(
    TypeTableSortMethodEnum,
    ModelTableSortMethod,
  );

  public static byValue(
    searchValue: string,
    defaultValue?: ModelTableSortMethod,
  ): ModelTableSortMethod {
    return this.by(
      this,
      ModelTableSortMethod,
      'value',
      searchValue,
      defaultValue,
    );
  }
}
