export const AddFavoriteStore = 'ADD_FAVORITE_STORE';
export type TypeAddFavoriteStore = {
  profile: number;
  store: number;
  stores: number[];
};

export const AddFavoriteEcommerce = 'ADD_FAVORITE_ECOMMERCE';
export type TypeAddFavoriteEcommerce = {
  profile: number;
  ecommerce: number;
};

export const AddFavoriteCountry = 'ADD_FAVORITE_COUNTRY';
export type TypeAddFavoriteCountry = {
  profile: number;
  country: string;
};

export const AddFavoriteDepartment = 'ADD_FAVORITE_DEPARTMENT';
export type TypeAddFavoriteDepartment = {
  profile: number;
  department: number;
  departments: number[];
};

export const AddFavoriteSubDepartment = 'ADD_FAVORITE_SUB_DEPARTMENT';
export type TypeAddFavoriteSubDepartment = {
  profile: number;
  subDepartment: number;
};

export const AddFavoriteFamily = 'ADD_FAVORITE_FAMILY';
export type TypeAddFavoriteFamily = {
  profile: number;
  family: number;
};

export const AddFavoriteModel = 'ADD_FAVORITE_MODEL';
export type TypeAddFavoriteModel = {
  profile: number;
  model: number;
};

export const ClearFavoriteStore = 'CLEAR_FAVORITE_STORE';
export type TypeClearFavoriteStore = {
  profile: number;
};

export const RemoveFavoriteStore = 'REMOVE_FAVORITE_STORE';
export type TypeRemoveFavoriteStore = {
  profile: number;
  store: number;
};

export const ClearFavoriteEcommerce = 'CLEAR_FAVORITE_ECOMMERCE';
export type TypeClearFavoriteEcommerce = {
  profile: number;
};
export const RemoveFavoriteEcommerce = 'REMOVE_FAVORITE_ECOMMERCE';
export type TypeRemoveFavoriteEcommerce = {
  profile: number;
  ecommerce: number;
};

export const ClearFavoriteCountry = 'CLEAR_FAVORITE_COUNTRY';
export type TypeClearFavoriteCountry = {
  profile: number;
};
export const RemoveFavoriteCountry = 'REMOVE_FAVORITE_COUNTRY';
export type TypeRemoveFavoriteCountry = {
  profile: number;
  country: string;
};

export const ClearFavoriteDepartment = 'CLEAR_FAVORITE_DEPARTMENT';
export type TypeClearFavoriteDepartment = {
  profile: number;
};
export const RemoveFavoriteDepartment = 'REMOVE_FAVORITE_DEPARTMENT';
export type TypeRemoveFavoriteDepartment = {
  profile: number;
  department: number;
};

export const ClearFavoriteSubDepartment = 'CLEAR_FAVORITE_SUB_DEPARTMENT';
export type TypeClearFavoriteSubDepartment = {
  profile: number;
};
export const RemoveFavoriteSubDepartment = 'REMOVE_FAVORITE_SUB_DEPARTMENT';
export type TypeRemoveFavoriteSubDepartment = {
  profile: number;
  subDepartment: number;
};

export const ClearFavoriteFamily = 'CLEAR_FAVORITE_FAMILY';
export type TypeClearFavoriteFamily = {
  profile: number;
};
export const RemoveFavoriteFamily = 'REMOVE_FAVORITE_FAMILY';
export type TypeRemoveFavoriteFamily = {
  profile: number;
  family: number;
};

export const ClearFavoriteModel = 'CLEAR_FAVORITE_MODEL';
export type TypeClearFavoriteModel = {
  profile: number;
};

export const RemoveFavoriteModel = 'REMOVE_FAVORITE_MODEL';
export type TypeRemoveFavoriteModel = {
  profile: number;
  model: number;
};

export type ActionTypesRemoveFavorite =
  | TypeRemoveFavoriteCountry
  | TypeRemoveFavoriteStore
  | TypeRemoveFavoriteEcommerce
  | ActionTypesRemoveFavoriteProduct;

export type ActionTypesRemoveFavoriteProduct =
  | TypeRemoveFavoriteDepartment
  | TypeRemoveFavoriteSubDepartment
  | TypeRemoveFavoriteFamily
  | TypeRemoveFavoriteModel;

export type ActionTypesAddFavorite =
  | ActionTypesAddFavoriteTier
  | ActionTypesAddFavoriteProduct;

export type ActionTypesAddFavoriteTier =
  | TypeAddFavoriteCountry
  | ActionTypesAddFavoriteStoreLike;

export type ActionTypesAddFavoriteStoreLike =
  | TypeAddFavoriteStore
  | TypeAddFavoriteEcommerce;

export type ActionTypesAddFavoriteProduct =
  | TypeAddFavoriteDepartment
  | TypeAddFavoriteSubDepartment
  | TypeAddFavoriteFamily
  | TypeAddFavoriteModel;
