import Languages from 'common/i18n/languages';

interface INavigatorChrome extends Navigator {
  userLanguage: string;
}

export default class NavigatorUtils {
  public static readonly DEFAULT_LANGUAGE = 'en';

  public static getNavigator(): INavigatorChrome {
    return window.navigator as INavigatorChrome;
  }

  private static getCodeLanguage(language) {
    return language.split('-')[0].toLowerCase();
  }

  public static getLanguage(): string {
    const acceptLanguages = Object.keys(Languages);

    const navigator = this.getNavigator();
    let userLanguage = this.getCodeLanguage(
      navigator.language || navigator.userLanguage,
    );

    if (typeof navigator.languages === 'object') {
      const prefereUserLanguages = navigator.languages;
      for (
        let i = 0;
        !acceptLanguages.includes(userLanguage) &&
        i < prefereUserLanguages.length;
        i += 1
      ) {
        userLanguage = this.getCodeLanguage(prefereUserLanguages[i]);
      }
    }

    if (!acceptLanguages.includes(userLanguage)) {
      return this.DEFAULT_LANGUAGE;
    }

    return userLanguage;
  }
}
