import {
  AddFavoriteCountry,
  AddFavoriteDepartment,
  AddFavoriteEcommerce,
  AddFavoriteFamily,
  AddFavoriteModel,
  AddFavoriteStore,
  AddFavoriteSubDepartment,
  ClearFavoriteCountry,
  ClearFavoriteDepartment,
  ClearFavoriteEcommerce,
  ClearFavoriteFamily,
  ClearFavoriteModel,
  ClearFavoriteStore,
  ClearFavoriteSubDepartment,
  RemoveFavoriteCountry,
  RemoveFavoriteDepartment,
  RemoveFavoriteEcommerce,
  RemoveFavoriteFamily,
  RemoveFavoriteModel,
  RemoveFavoriteStore,
  RemoveFavoriteSubDepartment,
} from 'pages/favorites/constants/action-types';

import AbstractEnumType from 'common/models/enums/types/abstract-enum-type';
import ModelTypeTreeLevel from 'common/models/enums/models/type-tree-level';
import TypeTableAggregationEnum from 'common/models/enums/types/TypeTableAggregationEnum';

export default class TypeTreeLevel extends AbstractEnumType {
  public static readonly PAYS = new ModelTypeTreeLevel(
    'COUNTRY',
    15,
    'countries',
    'country',
    AddFavoriteCountry,
    RemoveFavoriteCountry,
    ClearFavoriteCountry,
    'codePays',
    null,
    null,
    'countries',
    false,
    'country',
    'countries',
    null,
    TypeTableAggregationEnum.DEPARTMENTS,
    'byCountries',
  );

  public static readonly REGION = new ModelTypeTreeLevel(
    'REGION',
    13,
    'regions',
    'region',
    null,
    null,
    null,
    'idStore',
    TypeTreeLevel.PAYS,
    'region',
    'regions',
    true,
    'region',
    'regions',
    'tree/agglo',
    TypeTableAggregationEnum.DEPARTMENTS,
    'byRegions',
  );

  public static readonly STORE = new ModelTypeTreeLevel(
    'STORE',
    7,
    'stores',
    'store',
    AddFavoriteStore,
    RemoveFavoriteStore,
    ClearFavoriteStore,
    'idStore',
    TypeTreeLevel.PAYS,
    'physical store',
    'stores',
    true,
    'store',
    'stores',
    null,
    TypeTableAggregationEnum.DEPARTMENTS,
    'byThirds',
  );

  public static readonly ECOMMERCE = new ModelTypeTreeLevel(
    'ECOMMERCE',
    50,
    'stores',
    'ecommerce',
    AddFavoriteEcommerce,
    RemoveFavoriteEcommerce,
    ClearFavoriteEcommerce,
    'idStore',
    TypeTreeLevel.PAYS,
    'ecommerce store',
    null,
    true,
    'ecommerce',
    'ecommerces',
    null,
    TypeTableAggregationEnum.DEPARTMENTS,
    'byThirds',
  );

  public static readonly DEPARTMENT = new ModelTypeTreeLevel(
    'DEPARTMENT',
    4,
    'departments',
    'department',
    AddFavoriteDepartment,
    RemoveFavoriteDepartment,
    ClearFavoriteDepartment,
    'idDepartment',
    null,
    'sport',
    'sports',
    null,
    'department',
    'departments',
    'labels/product',
    TypeTableAggregationEnum.GEOGRAPHICAL,
    'byDepartments',
  );

  public static readonly SUB_DEPARTMENT = new ModelTypeTreeLevel(
    'SUB_DEPARTMENT',
    3,
    'sub_departments',
    'sub-department',
    AddFavoriteSubDepartment,
    RemoveFavoriteSubDepartment,
    ClearFavoriteSubDepartment,
    'idSubDepartment',
    TypeTreeLevel.DEPARTMENT,
    'sub_sports',
    'subSports',
    null,
    'subDepartment',
    'subDepartments',
    'labels/product',
    TypeTableAggregationEnum.GEOGRAPHICAL,
    'bySubDepartments',
  );

  public static readonly FAMILY = new ModelTypeTreeLevel(
    'FAMILY',
    2,
    'families',
    'family',
    AddFavoriteFamily,
    RemoveFavoriteFamily,
    ClearFavoriteFamily,
    'idFamily',
    TypeTreeLevel.SUB_DEPARTMENT,
    'family',
    'families',
    null,
    'family',
    'families',
    'labels/product',
    TypeTableAggregationEnum.GEOGRAPHICAL,
    'byFamilies',
  );

  public static readonly MODEL = new ModelTypeTreeLevel(
    'MODEL',
    1,
    'models',
    'model',
    AddFavoriteModel,
    RemoveFavoriteModel,
    ClearFavoriteModel,
    'idModel',
    TypeTreeLevel.FAMILY,
    'model',
    'models',
    null,
    'model',
    'models',
    'labels/product',
    TypeTableAggregationEnum.GEOGRAPHICAL,
    'byModels',
  );

  public static readonly ALL: ModelTypeTreeLevel[] = this.all(
    TypeTreeLevel,
    ModelTypeTreeLevel,
  );

  public static byValue(searchValue: number): ModelTypeTreeLevel {
    return this.by(this, ModelTypeTreeLevel, 'value', searchValue);
  }
}
