import AbstractEnumType from 'common/models/enums/types/abstract-enum-type';
import ModelTableAggregation from 'common/models/enums/models/ModelTableAggregation';

export default class TypeTableAggregationEnum extends AbstractEnumType {
  public static readonly GEOGRAPHICAL = new ModelTableAggregation(
    'GEOGRAPHICAL',
    'aggregation-geographical',
    true,
  );

  public static readonly DEPARTMENTS = new ModelTableAggregation(
    'DEPARTMENTS',
    'aggregation-departments',
    false,
  );

  public static readonly COUNTRIES = new ModelTableAggregation(
    'COUNTRIES',
    'aggregation-countries',
    false,
  );

  public static readonly ALL: ModelTableAggregation[] = this.all(
    TypeTableAggregationEnum,
    ModelTableAggregation,
  );

  public static byValue(
    searchValue: string,
    defaultValue?: ModelTableAggregation,
  ): ModelTableAggregation {
    return this.by(
      this,
      ModelTableAggregation,
      'value',
      searchValue,
      defaultValue,
    );
  }
}
