import AbstractEnumType from 'common/models/enums/types/abstract-enum-type';
import ModelTableColumn from 'common/models/enums/models/ModelTableColumn';

export default class TypeTableColumnEnum extends AbstractEnumType {
  public static readonly RANK = new ModelTableColumn(
    'rank',
    'rang',
    null,
    1,
    true,
  );

  public static readonly ID = new ModelTableColumn('id', 'id', null, 2, true);

  public static readonly LABEL = new ModelTableColumn(
    'label',
    'store',
    null,
    3,
    true,
  );

  public static readonly DELTA = new ModelTableColumn(
    'delta',
    'delta',
    null,
    null,
    true,
    false,
    'delta-long',
  );

  public static readonly SHARE = new ModelTableColumn(
    'share',
    'share',
    null,
    null,
    null,
    false,
  );

  public static readonly ALL = this.all<ModelTableColumn>(
    TypeTableColumnEnum,
    ModelTableColumn,
  ).filter((column) => column.enabled);

  public static byValue(
    searchValue: string,
    defaultValue?: ModelTableColumn,
  ): ModelTableColumn {
    return this.by(this, ModelTableColumn, 'value', searchValue, defaultValue);
  }
}
